import React from "react";
// import { useState } from "react";
// import ResponsiveDialog from "../modal/ResponsiveDialog";

export default function FeatureImgThree() {
  // const [open, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <span className="text-uppercase color-secondary sub-title">
                  Nuestro Metodo
                </span>
                <h2>
                  Conoce nuestro metodo de FSELL-PRO especializado en
                  neuroventas inmobiliarias
                </h2>
                <p>
                  Descubra cómo la tecnología revoluciona el sector inmobiliario
                  con nuestro sistema innovador de ventas digitales.
                  Simplificamos el proceso de comprar y vender propiedades,
                  ofreciendo una experiencia totalmente digital, segura y
                  eficiente.
                </p>
              </div>
            </div>
            <div
              className="col-md-4"
              // style={{ marginRight: "1rem" }}
            >
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/_T9eVHxlCIc?si=ZTA9Yx74HeC60QhP&amp;controls=0"
                title="Guia Valencia 2024"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen={false}
              ></iframe>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

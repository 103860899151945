import React from "react";
import { useState } from "react";
import ResponsiveDialog from "../modal/ResponsiveDialog";

export default function FeatureImgThree() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <section id="features" className="feature-section ptb-100" style={{ background: "linear-gradient(0deg, #f4f7fa 0%, rgba(255,255,255,1) 100%)"}}>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
            <div className="download-img" onClick={handleOpen}>
                <img
                  src="assets/guias/tendencias-valencia-2024c.jpg"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            {/* </div> */}
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <span className="text-uppercase color-secondary sub-title">
                  Gratis
                </span>
                <h2>Descarga gratis la guia de tendencias Valencia 2024</h2>
                <p>
                Esta guía te ayudará a vender mejor tu propiedad y a comprender el valor de mantener tu patrimonio.
                Descárgala ahora, es totalmente gratuita <span className="color-secondary">totalmente gratuita</span>.
                </p>
                <></>
                <button
                  onClick={handleOpen}
                  type="button"
                  name="submit"
                  id="submit"
                  className="btn solid-btn btn-block"
                >
                  Descargar guia
                </button>
              </div>
            </div>
          </div>
        </div>
        <ResponsiveDialog
          open={open}
          handleClose={handleClose}
          titleButton={"Descargar Guia"}
          title={"Descarga la guia Gratis"}
          subTitle={" "}
        />
      </section>
    </>
  );
}

import React from "react";
import { useState } from "react";
import ResponsiveDialog from "../modal/ResponsiveDialog";

export default function Subsribe() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="shape-img subscribe-wrap">
        <img
          src="assets/img/footer-top-shape.png"
          alt="footer shape"
          className="img-fluid"
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-3">
              <div className="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block">
                <div className="d-flex align-items-center">
                  <button className="btn masinfo-play-btn" onClick={handleOpen}>
                    <span className="ti-info-alt"></span> Mas informacion
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResponsiveDialog
        open={open}
        handleClose={handleClose}
        titleButton={"Enviar"}
        title={"Mas informacion"}
        subTitle={""}
      />
    </>
  );
}
